import Typography from '@mui/material/Typography'
import React from 'react'
import Layout from '../components/Layout'
import { RecentPosts } from '../components/RecentPosts'
import Seo from '../components/Seo'
import { TagsGallery } from '../components/TagsGallery'

interface BlogProps {
	location?: PageLocation, // Not available during SSR
}

const Blog: React.FC<BlogProps> = () => {

	return (
		<Layout>
			<Seo title="The blog" description="The dev tavern blog posts" />
			<Typography fontWeight="bold" variant="h1" gutterBottom>The blog</Typography>
			<Typography fontWeight="bold" variant="h3" my="2rem" mt="4rem">Top categories</Typography>
			<TagsGallery />
			<Typography fontWeight="bold" variant="h3" my="2rem" mt="4rem">Recent posts</Typography>
			<RecentPosts />
		</Layout>
	)
}

export default Blog