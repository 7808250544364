import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { SmallPostCard } from '../components/SmallPostCard';
import { GetRecentPostsQuery } from '../graphqlTypes';

interface RecentPostsProps {
}

export const RecentPosts: React.FC<RecentPostsProps> = () => {
	const data: GetRecentPostsQuery = useStaticQuery(graphql`
		query GetRecentPosts {
			allMdx(sort: {fields: [frontmatter___date], order: DESC}, limit: 10) {
				nodes {
					id

					frontmatter {
						title
						description {
							short
						}
						tags
						banner {
							childImageSharp {
								gatsbyImageData(height: 600)
							}
						}
						date(formatString: "MMMM DD, YYYY")
					}

					fields {
						defaultSlug
					}
				}
			}
		}
	`)

	const posts = data.allMdx.nodes

	if (posts.length === 0) {
		return <Alert severity="info">No posts published yet! Don't be impatient! ;)</Alert>
	}

	return (
		<Box id="LatestPosts__wrapper" display="flex" flexDirection="column" gap="2rem">
			{posts.map((post) => (
				<SmallPostCard
					key={post.id}
					post={{
						title: post.frontmatter.title,
						description: post.frontmatter.description.short,
						tags: post.frontmatter.tags,
						banner: post.frontmatter.banner?.childImageSharp?.gatsbyImageData,
						slug: post.fields.defaultSlug,
						date: post.frontmatter.date,
					}}
					sx={{ minHeight: "150px" }}
				/>
			))}
			<Link to="/blog/tags/all/1" style={{ textDecoration: "none", color: "inherit", textAlign: "right" }}>
				<Typography variant="h6" component="div">Browse all {<OpenInNewIcon />}</Typography>
			</Link>
		</Box>
	)
}