import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from 'react'
import { GetTagsQuery } from "../graphqlTypes"
import { tagSlug } from "../utils"

interface TagsGalleryProps {

}

export const TagsGallery: React.FC<TagsGalleryProps> = () => {

	const data: GetTagsQuery = useStaticQuery(graphql`
		query GetTags {
			allTagsYaml {
				nodes {
					identifier
					title
				}
			}
		}
	`)

	const tags = data.allTagsYaml.nodes

	return (
		<Box className="TagsGallery__wrapper" display="flex" gap="0.5rem" flexWrap="wrap">
			{tags.map(tag => (
				<Link key={tag.identifier} to={tagSlug(tag.identifier)} style={{textDecoration: "none", color: "inherit"}}>
					<Chip
						variant="filled"
						color="secondary"
						label={tag.title}
						sx={{ cursor: "pointer" }}
					/>
				</Link>
			))}
		</Box>
	)
}